import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import { BLOCKS } from "@contentful/rich-text-types"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Navigation from "../components/navigation"
import { rhythm, scale } from "../utils/typography"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PhotosTemplate = ({ data, pageContext, location }) => {
  const photo = data.contentfulImages
  const siteTitle = data.site.siteMetadata.title
  const image = getImage(photo.image.gatsbyImageData)
  const social = data.site.siteMetadata.social

  return (
    <Layout location={location} large={true} title={siteTitle} social={social}>
      <Seo title={photo.title} description={photo.title} />
      <Navigation />
      <h1>{photo.title}</h1>
      <Link to={`/photos`}>
        <p> Go Back To All Photos </p>
      </Link>
      <GatsbyImage className="picture" image={image} alt={photo.title} />
      <p>{photo.description}</p>
      <p>
        <strong>Lens:</strong>
      </p>
      <p>{photo.lens.name}</p>
      <p>{photo.lens.model}</p>

      <p>
        <strong>Camera:</strong>
      </p>
      <p>{photo.camera.name}</p>
      <p>{photo.camera.model}</p>

      <p>
        <strong>Took at:</strong>
      </p>
      <p>{photo.dateTaken}</p>
      <p>
        <strong>Location:</strong>
      </p>
      <img
        src={`https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/pin-m(${photo.location.lon},${photo.location.lat})/${photo.location.lon},${photo.location.lat},11/500x300?access_token=pk.eyJ1IjoibWJpYW5jaGlociIsImEiOiJja3NhaG1icGkwODZ4MnBzMnpmb3N6M3g5In0.soP9_8dUN7KSSYgIkEud2w`}
      />
    </Layout>
  )
}

export default PhotosTemplate

export const pageQuery = graphql`
  query ImagesUsingId($id: String!) {
    contentfulImages(id: { eq: $id }) {
      id
      title
      dateTaken
      imageTags
      lens {
        name
        model
        id
      }
      camera {
        name
        model
        id
      }
      location {
        lon
        lat
      }
      description
      image {
        id
        gatsbyImageData(quality: 8, placeholder: DOMINANT_COLOR)
      }
    }
    site {
      siteMetadata {
        title
        social {
          title
          url
        }
      }
    }
  }
`
